// dependencies from angular legacy
import '../node_modules/jquery/dist/jquery.min.js';
import '../node_modules/lodash/lodash.min.js';
import '../node_modules/chart.js/dist/Chart.min.js';
import '../node_modules/pdfmake/build/pdfmake.min.js';
import '../node_modules/pdfmake/build/vfs_fonts.js';
import '../node_modules/angular/angular.min.js';
import '../node_modules/angular-animate/angular-animate.min.js';
import '../node_modules/angular-aria/angular-aria.min.js';
import '../node_modules/angular-cookies/angular-cookies.min.js';
import '../node_modules/angular-messages/angular-messages.min.js';
import '../node_modules/angular-resource/angular-resource.min.js';
import '../node_modules/angular-route/angular-route.min.js';
import '../node_modules/angular-sanitize/angular-sanitize.min.js';
import '../node_modules/angular-strap/dist/angular-strap.min.js';
import '../node_modules/angular-strap/dist/angular-strap.tpl.min.js';
import '../node_modules/angular-touch/angular-touch.min.js';
import '../node_modules/angular-chart.js/dist/angular-chart.min.js';
import '../node_modules/angular-loading-bar/build/loading-bar.min.js';
import '../node_modules/bootstrap-sass/assets/javascripts/bootstrap.min.js';
import '../node_modules/sweetalert/dist/sweetalert-dev.js';
import '../node_modules/store2/dist/store2.min.js';

import '../node_modules/angular-ui-bootstrap/dist/ui-bootstrap-tpls.js';

import '../node_modules/angular-ui-router/release/angular-ui-router.min.js';
import '../node_modules/ui-select/dist/select.min.js';
import '../node_modules/@flowjs/ng-flow/dist/ng-flow.min.js';
import '../node_modules/ng-csv/build/ng-csv.min.js';
import '../node_modules/restangular/dist/restangular.min.js';
import '../node_modules/angular-csv-import/dist/angular-csv-import.js';
import '../node_modules/angular-google-chart/ng-google-chart.min.js';
import '../node_modules/angular-simple-logger/dist/angular-simple-logger.min.js';
import '../node_modules/angular-google-maps/dist/angular-google-maps.min.js';
import '../node_modules/angular-dynamic-locale/dist/tmhDynamicLocale.min.js';
import '../node_modules/ng-file-upload/dist/ng-file-upload.min.js';

import '../node_modules/leaflet-easybutton/src/easy-button.js';
import '../node_modules/angular-leaflet-directive/dist/angular-leaflet-directive.min.js';

import '../node_modules/moment/min/moment-with-locales.min.js';
import '../node_modules/moment-timezone/builds/moment-timezone-with-data.min.js';
import '../node_modules/pusher-angular/lib/pusher-angular.min';

import '../node_modules/angular-http-auth/dist/http-auth-interceptor.min.js';
import './scripts/app.js';
import './scripts/core/keycloak-service.js';

import './scripts/core/config.js';
import './scripts/core/authentication-service.js';
import './scripts/core/pusher-service.js';
import './scripts/core/restangular-helper.js';
import './scripts/core/common.js';

import './scripts/directives/pickadate.js';
import './scripts/directives/geo-complete.js';
import './scripts/directives/table-header-filter.js';
import './scripts/directives/contact-list.js';
import './scripts/directives/contact-association-list.js';

import './scripts/filters/utc-to-local.js';

import './scripts/extras/swal-helper.js';
import './scripts/extras/leaflet-helper.js';

import './scripts/products/config.routes.js';
import './scripts/products/products/config.routes.js';
import './scripts/products/products/products.js';
import './scripts/products/foodcategories/config.routes.js';
import './scripts/products/foodcategories/foodcategories.js';
import './scripts/products/foodtags/config.routes.js';
import './scripts/products/foodtags/foodtags.js';
import './scripts/products/foodtypes/config.routes.js';
import './scripts/products/foodtypes/foodtypes.js';
import './scripts/products/ingredients/config.routes.js';
import './scripts/products/ingredients/ingredients.js';
import './scripts/products/suppliers/config.routes.js';
import './scripts/products/suppliers/suppliers.js';
import './scripts/products/packagingtypes/config.routes.js';
import './scripts/products/packagingtypes/packagingtypes.js';
import './scripts/products/menus/config.routes.js';
import './scripts/products/menus/menus.js';

import './scripts/locations/config.routes.js';
import './scripts/locations/pricinggroups/config.routes.js';
import './scripts/locations/pricinggroups/pricinggroups.js';
import './scripts/locations/locations/config.routes.js';
import './scripts/locations/locations/locations.js';
import './scripts/locations/companies/config.routes.js';
import './scripts/locations/companies/companies.js';
import './scripts/locations/contractproducts/config.routes.js';
import './scripts/locations/contractproducts/contractproducts.js';
import './scripts/locations/logisticscompanies/config.routes.js';
import './scripts/locations/logisticscompanies/logisticscompanies.js';

import './scripts/inventories/config.routes.js';
import './scripts/inventories/inventorylocations/config.routes.js';
import './scripts/inventories/inventorylocations/inventorylocations.js';
import './scripts/inventories/deliverybatches/config.routes.js';
import './scripts/inventories/deliverybatches/deliverybatches.js';
import './scripts/inventories/picklists/config.routes.js';
import './scripts/inventories/picklists/picklists.js';
import './scripts/inventories/inventoryoverview/config.routes.js';
import './scripts/inventories/inventoryoverview/coolcell.js';
import './scripts/inventories/inventoryoverview/temperature-recall.js';
import './scripts/inventories/inventoryoverview/inventoryoverview.js';
import './scripts/inventories/items/config.routes.js';
import './scripts/inventories/items/items.js';
import './scripts/inventories/supplierorders/config.routes.js';
import './scripts/inventories/supplierorders/supplierorders.js';
import './scripts/inventories/internalorders/config.routes.js';
import './scripts/inventories/internalorders/internalorders.js';

import './scripts/operations/config.routes.js';
import './scripts/operations/temp-charts.js';
import './scripts/operations/offline-history.js';
import './scripts/operations/ops-checklists.js';
import './scripts/operations/ops-drivers-training.js';
import './scripts/operations/ops-map-new.js';
import './scripts/operations/logisticsschedules/config.routes.js';
import './scripts/operations/logisticsschedules/logisticsschedules.js';
import './scripts/operations/logisticsbadges/config.routes.js';
import './scripts/operations/logisticsbadges/logisticsbadges.js';

import './scripts/users/config.routes.js';
import './scripts/users/accounts/config.routes.js';
import './scripts/users/accounts/accounts.js';
import './scripts/users/users/config.routes.js';
import './scripts/users/users/users.js';
import './scripts/users/home-delivery/config.routes.js';
import './scripts/users/home-delivery/delivery-defaults.js';
import './scripts/users/home-delivery/order-processing.js';
import './scripts/users/home-delivery/orders.js';
import './scripts/users/cashvouchers/config.routes.js';
import './scripts/users/cashvouchers/cashvouchers.js';
import './scripts/users/promos/config.routes.js';
import './scripts/users/promos/promos.js';
import './scripts/users/news/config.routes.js';
import './scripts/users/news/news.js';

import './scripts/contacts/contacts.js';

import './scripts/forecasting/config.routes.js';
import './scripts/forecasting/forecasting.js';
import './scripts/login/login.js';

import './scripts/models/user-model.js';
import './scripts/models/food-cat-model.js';
import './scripts/models/food-tag-model.js';
import './scripts/models/food-type-model.js';
import './scripts/models/ingredient-model.js';
import './scripts/models/supplier-model.js';
import './scripts/models/packaging-type-model.js';
import './scripts/models/pricing-group-model.js';
import './scripts/models/product-model.js';
import './scripts/models/product-item-model.js';
import './scripts/models/product-price-model.js';
import './scripts/models/product-ingredients-model.js';
import './scripts/models/location-model.js';
import './scripts/models/location-tag-model.js';
import './scripts/models/inventory-location-model.js';
import './scripts/models/company-model.js';
import './scripts/models/delivery-batch-model.js';
import './scripts/models/picklist-products-model.js';
import './scripts/models/logistic-company-model.js';
import './scripts/models/cashbook-position-model.js';
import './scripts/models/user-order-model.js';
import './scripts/models/logistics-schedule-model.js';
import './scripts/models/cash-voucher-model.js';
import './scripts/models/promo-rule-model.js';
import './scripts/models/promo-rule-tag-model.js';
import './scripts/models/logistic-badge-model.js';
import './scripts/models/payment-method-model.js';
import './scripts/models/news-model.js';
import './scripts/models/contact-model.js';
import './scripts/models/contact-type-model.js';
import './scripts/models/contract-product-model.js';
import './scripts/models/user-profile-model.js';
import './scripts/models/binning-model.js';

import './scripts/models/forecast-model.js';
import './scripts/models/forecast-week-model.js';
import './scripts/models/menu-model.js';
import './scripts/models/metric-model.js';
import './scripts/models/supplier-order-model.js';
import './scripts/models/supplier-order-item-model.js';
import './scripts/models/address-state-model.js';
import './scripts/models/location-group-model.js';
import './scripts/models/service-tier-model.js';
import './scripts/models/users-model.js';
import './scripts/models/home-delivery-model.js';
import './scripts/models/delivery-tour-model.js';

// styles

import '../node_modules/sweetalert/dist/sweetalert.css';
import '../node_modules/ui-select/dist/select.css';
import '../node_modules/angular-chart.js/dist/angular-chart.css';
import '../node_modules/spinkit/css/spinkit.css';
import '../node_modules/angular-loading-bar/build/loading-bar.css';
import '../node_modules/jquery.fileapi/jcrop/jquery.Jcrop.min.css';
import '../node_modules/leaflet-easybutton/src/easy-button.css';

import './styles/main.scss';

// assets
import './images/felfel_twig_dark.png';
import './images/felfel_typeface.png';
