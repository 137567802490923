(function() {
    angular.module('felfel').factory('Users', Users);

    Users.$inject = ['Common', 'Restangular'];
    function Users(Common, Restangular) {
        const Users = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.addResponseInterceptor(function(
                data,
                operation,
                what,
                url,
                response,
                deferred
            ) {
                // exclude contacts end point
                if (url.indexOf('contacts') !== -1) {
                    return data;
                }

                return Common.convertToCamelCase(data, 5);
            });

            RestangularConfigurer.addRequestInterceptor(function(element, operation, what, url) {
                if (_.indexOf(['get', 'head', 'options'], operation) == -1) {
                    return Common.convertToSnakeCase(element, 5);
                }
            });

            RestangularConfigurer.setRestangularFields({
                id: '',
            });

            RestangularConfigurer.setBaseUrl(REACT_APP_USERS_URI);
        });
        return Users.service('api/');
    }
})();
